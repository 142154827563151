




























































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts';
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { Competence } from '../../api/models/competences/competence';
import CreateCompetence from './CreateCompetence.vue';
import UpdateCompetence from './EditCompetence.vue';

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateCompetence,
    UpdateCompetence,
  },
})
export default class ListeCompetences extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewCompetenceSidebarActive = false;
  isUpdateCompetenceSidebarActive = false;

  tableColumns = [{ key: "libelle", sortable: true }, { key: "libelle", sortable: true }, {key: "countUtilisateurs", label: "Nombre de membres", sortable: false}, { key: "actions" }];
  selectedCompetenceId = "";

  paginatedListcompetences = new PaginatedList<Competence>();

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListcompetences.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListcompetences.items.length
    );
  }

  async created() {
    this.loadListCompetences({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  async loadListCompetences(params: any) {
    if (!params || params?.reset) {
      this.paginatedListcompetences = new PaginatedList<Competence>();
    }
    await this.$http.myressif.competences
      .paginatedList(this.currentPage ?? 1, this.perPage ?? 10)
      .then((response: PaginatedList<Competence>) => {
        this.paginatedListcompetences = response;
      });
  }

  selectCompetence(competenceId: string) {
    this.$router.push({
      name: "details-competences",
      params: { id: competenceId },
    });
  }

  editCompetence(competenceId: string) {
    this.selectedCompetenceId = competenceId;
    this.isUpdateCompetenceSidebarActive = true;
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cette compétence ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.competences
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'une compétence",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-competences");
        this.loadListCompetences({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }
}
